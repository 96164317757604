import { NotificationType } from "@components/Notifications"
import styled, { css } from "styled-components"

const WrapperBg = {
  general: css`
    background: ${(p) => p.theme.colors.purpleLight};
  `,
  success: css`
    background: ${(p) => p.theme.colors.green.icon};
  `,
  error: css`
    background: ${(p) => p.theme.colors.red.icon};
  `,
}

export const Wrapper = styled.div<{ type: NotificationType; href?: string }>`
  width: 100%;
  max-width: 22rem;
  color: white;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  transform: translateY(0%);
  padding: 1.25rem 1.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.082);
  margin-bottom: 1rem;
  ${(p) => (p.type ? WrapperBg[p.type] : null)}
`

export const CrossContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;

  svg {
    width: 13px;
    height: 13px;
    opacity: 0.7;
    transition: opacity 0.15s ease;

    path {
      fill: white;
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`
