import { ChevronRight } from "@components/Icons"
import { useDebounceScrollPosition } from "@lib/use-debounced-scroll-position"
import React, { FC, useEffect, useState } from "react"
import * as S from "./elements"

export type BackToTopStatus = "hidden" | "visible"

export const BackToTop: FC = () => {
  const scrollPosition = useDebounceScrollPosition(500)
  const [windowHeight, setWindowHeight] = useState<number | null>(null)
  const [status, setStatus] = useState<BackToTopStatus>("hidden")

  useEffect(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    if (
      windowHeight &&
      scrollPosition > windowHeight * 1.5 &&
      status === "hidden"
    ) {
      setStatus("visible")
    }
    if (
      windowHeight &&
      scrollPosition <= windowHeight * 1.5 &&
      status === "visible"
    ) {
      setStatus("hidden")
    }
  }, [windowHeight, scrollPosition]) // eslint-disable-line

  return (
    <>
      <S.Button onClick={scrollToTop} status={status}>
        <ChevronRight />
      </S.Button>
    </>
  )

  function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
}
