import { useIntl } from "@components/Intl"
import { prettyError } from "@config/errors"
import React, { createContext, useContext, useState } from "react"
import List from "./List"
export type NotificationType = "general" | "success" | "error"

export type Notification = {
  id: string
  text: string
  type: NotificationType
}

interface AddNotificationArgs {
  text: string
  type: NotificationType
}

interface NotificationsState {
  notifications: Notification[]
}

interface NotificationsUpdate {
  addNotification: (args: AddNotificationArgs) => void
  removeNotification: (id: string) => void
  handleException: (error: Error) => void
}

const NotificationsStateContext = createContext<NotificationsState>({
  notifications: [],
})
const NotificationsUpdateContext = createContext<NotificationsUpdate>({
  addNotification: () => null,
  removeNotification: () => null,
  handleException: (error) => null,
})

const getId = (): string => Math.random().toString(36).substr(5)

export const NotificationsProvider: React.FC<{}> = ({ children }) => {
  const [state, setState] = useState<NotificationsState>({ notifications: [] })
  const { locale } = useIntl()

  const addNotification = ({ text, type }: AddNotificationArgs) =>
    setState({
      notifications: [...state.notifications, { id: getId(), text, type }],
    })

  const removeNotification = (id: string) => {
    setState((s) => ({
      notifications: s.notifications.filter((i) => i.id !== id),
    }))
  }
  const handleException = (error: Error) => {
    if (process.env.NEXT_PUBLIC_STAGE !== "production") {
      console.error(error)
    }
    setState((s) => ({
      notifications: [
        ...state.notifications,
        {
          id: getId(),
          text: prettyError(error.message, locale),
          type: "error",
        },
      ],
    }))
  }

  return (
    <>
      <NotificationsUpdateContext.Provider
        value={{ addNotification, removeNotification, handleException }}
      >
        {children}
      </NotificationsUpdateContext.Provider>
      <NotificationsStateContext.Provider value={state}>
        <List
          removeNotification={removeNotification}
          notifications={state.notifications}
        />
      </NotificationsStateContext.Provider>
    </>
  )
}

export function useNotificationsUpdate() {
  const ctx = useContext(NotificationsUpdateContext)
  if (typeof ctx === "undefined") {
    throw new Error(
      "useNotificationsUpdate must be within a NotificationsUpdate Provider"
    )
  }
  return ctx
}
