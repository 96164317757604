import { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

export function useDebounceScrollPosition(maxWait = 50): number {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const debounced = useDebouncedCallback(
    () => {
      const currentPos =
        window.pageYOffset || document.documentElement.scrollTop
      setScrollPosition(currentPos)
    },
    maxWait,
    { maxWait }
  )

  useEffect(() => {
    window.addEventListener("scroll", debounced, false)
    return () => {
      window.removeEventListener("scroll", debounced, false)
    }
  }, [debounced])

  return scrollPosition
}
