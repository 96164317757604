import { Box } from "@components/Layout"
import { Paragraph } from "@components/Typography"
import { useIntl } from "@components/Intl"
import Footer from "@components/Footer"
import React, { FC } from "react"

type ErrorPageProps = {
  statusCode: number | null | undefined
}

export const ErrorPage: FC<ErrorPageProps> = ({ statusCode }) => {
  const { messages } = useIntl()
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        maxWidth="22rem"
        textAlign="center"
        margin="0 auto"
        flexDirection="column"
        justifyContent="center"
        py="35vh"
      >
        <h1>
          {statusCode} {messages.global.oops}
        </h1>
        <Paragraph>
          {statusCode === 404
            ? messages.global.error404
            : messages.global.errorXxx}
        </Paragraph>
      </Box>
      <Footer />
    </>
  )
}
