import { useIntl } from "@components/Intl"
import { Anchor, Paragraph } from "@components/Typography"
import React, { useState } from "react"
import CookieModal from "./CookieModal"
import * as S from "./elements"
import Settings from "./settings.svg"
import { useCookies } from "./hooks/use-cookies"

const CookieBar: React.FC = () => {
  const { cookiesAreSet, updateCookieSettings } = useCookies()
  const [isCookiePopupVisible, setIsCookiePopupVisible] = useState(false)
  const {
    messages: { cookies },
  } = useIntl()

  const acceptAll = () => {
    updateCookieSettings(["personal", "analytical", "functional"])
  }

  if (cookiesAreSet) return null

  return (
    <>
      <S.Wrapper>
        <Paragraph as="span" fontSize="12px" lineHeight="1.4">
          {cookies.noticeMsg}{" "}
          <S.ShowSettings
            display="inline-flex"
            alignItems="center"
            as="button"
            onClick={() => setIsCookiePopupVisible(true)}
          >
            <Anchor>{cookies.noticeMsgSettings}</Anchor>
            <Settings />
          </S.ShowSettings>
        </Paragraph>
        <S.OkayButton variant="white-border" onClick={() => acceptAll()}>
          {cookies.acceptAll}
        </S.OkayButton>
      </S.Wrapper>
      <CookieModal
        isVisible={isCookiePopupVisible}
        onClose={() => setIsCookiePopupVisible(false)}
      />
    </>
  )
}

export default CookieBar
