import { FC, useEffect } from "react"
import { useCookies } from "./Cookies/hooks/use-cookies"
import Head from "next/head"

export const TagManager: FC = () => {
  const { cookieSettings, cookiesAreSet } = useCookies()
  const ad_storage = cookieSettings.includes("personal") ? "granted" : "denied"

  useEffect(() => {
    if (!cookiesAreSet) return
    if (window.gtag === undefined) return
    // Update consent in Google Tag Manager
    // This allows us to load tags conditionally or with different settings based on given consent
    window.gtag("consent", "update", {
      ad_storage: cookieSettings.includes("personal") ? "granted" : "denied",
    })
  }, [cookiesAreSet, cookieSettings])

  return (
    <>
      <noscript>
        <iframe
          title="tag-manager"
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <Head>
        <script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `
          // Setup gtag function & default consent
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': '${ad_storage}',
              'analytics_storage': 'granted'
            });
            // Tag manager
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
      `,
          }}
        />
      </Head>
    </>
  )
}
