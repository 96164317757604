import { NProgress } from "@tanem/react-nprogress"
import React from "react"

const TopLoadingBar = ({ isRouteChanging }) => (
  <NProgress isAnimating={isRouteChanging}>
    {({ isFinished, progress, animationDuration }) => (
      <div
        style={{
          opacity: isFinished ? 0 : 1,
          pointerEvents: "none",
          transition: `opacity ${animationDuration}ms linear`,
        }}
      >
        <div
          style={{
            background: "#341C4E",
            height: 2,
            left: 0,
            marginLeft: `${(-1 + progress) * 100}%`,
            position: "fixed",
            top: 0,
            transition: `margin-left ${animationDuration}ms linear`,
            width: "100%",
            zIndex: 1031,
          }}
        >
          <div
            style={{
              boxShadow: "0 0 10px #341C4E, 0 0 5px #341C4E",
              display: "block",
              height: "100%",
              opacity: 1,
              position: "absolute",
              right: 0,
              transform: "rotate(3deg) translate(0px, -4px)",
              width: 100,
            }}
          />
        </div>
      </div>
    )}
  </NProgress>
)

export default TopLoadingBar
