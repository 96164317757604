import { BackToTop } from "@components/BackToTop"
import CookieBar from "@components/Cookies"
import { IntlProvider } from "@components/Intl"
import { LoadCart } from "@components/LoadCart"
import { NotificationsProvider } from "@components/Notifications"
import Header from "@components/Page/Header"
import TopLoadingBar from "@components/TopLoadingBar"
import { getCookieSettings } from "@lib/cookies"
import { queryClient } from "@lib/query-client"
import { withApollo } from "@lib/withApollo"
import { CheckoutContextProvider } from "@screens/checkout/Context"
import { ErrorPage } from "@screens/error"
import * as Sentry from "@sentry/browser"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ApolloClient, NormalizedCacheObject } from "apollo-boost"
import { PageTransition } from "next-page-transitions"
import NextApp, { AppContext } from "next/app"
import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga"
import { hotjar } from "react-hotjar"
import { ThemeProvider } from "styled-components"
import { theme } from "../config/theme"
import "../styles/global.css"
import { TagManager } from "@components/TagManager"

if (typeof window !== "undefined" && window["__NEXT_DATA__"]) {
  if (window["__NEXT_DATA__"].buildId !== "development") {
    Sentry.configureScope((scope) => {
      scope.setTag("level", "client")
    })
    Sentry.init({
      dsn: "https://830bc78cb4bd4d7296ecdc9622a3972d@sentry.io/1499531",
      environment: "production",
    })
  }
}

export interface AppInterface extends NextApp {
  apollo: ApolloClient<NormalizedCacheObject>
  lang: string
  env: string
  clientUrl: string
}

class App extends NextApp<AppInterface> {
  state = {
    isRouteChanging: false,
  }
  prevRoute: null | string = null
  routeTimeout: null | number = null
  static async getInitialProps({ Component, ctx, router }: AppContext) {
    let pageProps = {}
    const lang = (router.query.lang as string) || "nl"

    // process.env is only filled on the server [x-now-deployment-url] is only avaible once deployed
    let clientUrl = process.env.CLIENT_URL
    if (
      ctx &&
      ctx.req &&
      ctx.req.headers &&
      ctx.req.headers["x-now-deployment-url"]
    ) {
      clientUrl = `https://${ctx.req.headers["x-now-deployment-url"] as string}`
    }
    const env = process.env.NEXT_PUBLIC_STAGE

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({
        ...ctx,
        lang: lang || "nl",
      })
    }

    return { pageProps, lang, env, clientUrl }
  }
  async componentDidMount() {
    const STAGE = process.env.NEXT_PUBLIC_STAGE
    const cookieSettings = getCookieSettings()
    const cookiesPersonalOk = cookieSettings.includes("personal")
    const cookiesAnalyticalOk = cookieSettings.includes("analytical")

    if (document && document.documentElement) {
      document.documentElement.classList.remove("no-js")
    }
    const { router } = this.props

    const routeChangeStartHandler = (newRoute) => {
      if (this.prevRoute !== newRoute.split("?")[0]) {
        this.prevRoute = newRoute.split("?")[0]

        // @ts-ignore
        this.routeTimeout = setTimeout(() => {
          this.setState({ isRouteChanging: true })
        }, 200)
      }
    }

    const routeChangeEndHandler = () => {
      if (this.routeTimeout) {
        clearTimeout(this.routeTimeout)
      }
      this.setState(() => ({
        isRouteChanging: false,
      }))
    }

    router.events.on("routeChangeStart", routeChangeStartHandler)
    router.events.on("routeChangeComplete", routeChangeEndHandler)
    router.events.on("routeChangeError", routeChangeEndHandler)

    // Init GA tracking in production
    if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID)
      ReactGA.plugin.require("ecommerce")

      // Use anonimized data if permission for analytical cookies has not been granted
      if (!cookiesAnalyticalOk) {
        ReactGA.set({ anonymizeIp: true })
      }
    }

    // Only init FB & Hotjar tracking in production, when cookies have been set and permission for personal cookies has been granted
    if (STAGE === "production" && cookiesPersonalOk) {
      ReactPixel.init("1131518043700489", undefined, {
        debug: true,
        autoConfig: true,
      })

      hotjar.initialize(1538633, 6)
    }

    router.events.on("routeChangeComplete", (path) => {
      if (STAGE === "preview" || STAGE === "production") {
        ReactGA.pageview(path)
      }

      if (STAGE === "production" && cookiesPersonalOk) {
        ReactPixel.pageView()
      }
    })
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })

    super.componentDidCatch && super.componentDidCatch(error, errorInfo)
  }

  render() {
    console.info(`API: ${process.env.NEXT_PUBLIC_API_URL}`)
    const { Component, pageProps, lang, router } = this.props
    const displayRegularHeader =
      !router.pathname.includes("/vote") &&
      !router.pathname.includes("/checkout") &&
      !router.pathname.includes("/bestellen") &&
      !router.pathname.includes("/invoice-download") &&
      !router.pathname.includes("/ondernemende-vrouw2023") &&
      !router.pathname.includes("/enterprising-women2023")
    const shouldRender404 = lang !== null && !["nl", "en"].includes(lang)

    return (
      <>
        <IntlProvider initialLocale={lang || "nl"}>
          <ThemeProvider theme={theme}>
            <NotificationsProvider>
              <CheckoutContextProvider>
                <QueryClientProvider client={queryClient}>
                  <>
                    <TagManager />
                    <LoadCart />
                    <BackToTop />
                    <TopLoadingBar {...this.state} />
                    {displayRegularHeader && (
                      <Header
                        mode={
                          router.pathname === "/" ||
                          router.route === "/[lang]" ||
                          router.pathname.includes("v2")
                            ? "slide-in"
                            : "sticky"
                        }
                      />
                    )}

                    <PageTransition
                      timeout={500}
                      classNames="animate-page page-transition"
                      key={router.route}
                    >
                      {shouldRender404 ? (
                        <ErrorPage statusCode={404} />
                      ) : (
                        // @ts-ignore
                        <Component {...pageProps} key={router.route} />
                      )}
                    </PageTransition>

                    {displayRegularHeader && <CookieBar />}
                    {process.env.NODE_ENV === "development" && (
                      <ReactQueryDevtools />
                    )}
                  </>
                </QueryClientProvider>
              </CheckoutContextProvider>
            </NotificationsProvider>
          </ThemeProvider>
        </IntlProvider>
      </>
    )
  }
}

export default withApollo({ ssr: true })(App)
