import { Cross } from "@components/Icons"
import { NotificationType } from "@components/Notifications"
import React, { useEffect } from "react"
import * as S from "./elements"

interface Props {
  type: NotificationType
  onClose: () => void
}

const Notification: React.FC<Props> = ({ type, children, onClose }) => {
  useEffect(() => {
    let mounted = true
    const timeout = setTimeout(() => {
      if (mounted) {
        onClose()
      }
    }, 2000)

    return () => {
      mounted = false
      clearTimeout(timeout)
    }
  })
  return (
    <S.Wrapper type={type}>
      {children}
      <S.CrossContainer onClick={onClose}>
        <Cross />
      </S.CrossContainer>
    </S.Wrapper>
  )
}

export default Notification
