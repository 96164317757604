import { breakpoints, theme } from "@config/theme"
import styled from "styled-components"
import { BackToTopStatus } from "."

export const Button = styled.button<{ status: BackToTopStatus }>`
  outline: 0;
  border: 0;
  padding: 1rem;
  position: fixed;
  transition: all 0.3s ease;
  right: 1rem;
  cursor: pointer;
  bottom: 10vh;
  background: ${theme.colors.purpleLight};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(p) => (p.status === "hidden" ? 0 : 1)};
  z-index: ${(p) => p.theme.zIndex.cookieNotice};

  @media screen and (min-width: ${breakpoints.small}) {
    padding: 1rem;
    opacity: ${(p) => (p.status === "hidden" ? 0 : 0.5)};

    &:hover {
      opacity: 1;
    }
  }

  svg {
    transform: rotate(-90deg);
    height: 1.25rem;
    width: 1.25rem;
    fill: white;

    @media screen and (min-width: ${breakpoints.small}) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
`

export const Span = styled.span`
  position: absolute;
  display: block;
  top: 0;
  height: 200vh;
  width: 2px;
  background: blue;
  pointer-events: none;
`
