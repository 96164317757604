import { Button } from "@components/Buttons"
import { Box } from "@components/Layout"
import { breakpoints } from "@config/theme"
import styled, { keyframes } from "styled-components"

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(1rem);
  }
`

export const Wrapper = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  bottom: 0;
  padding: 1.25rem 1.25rem;
  color: white;
  background: ${(p) => p.theme.colors.text.dark};
  max-width: 72rem;
  left: 0;
  right: 0;
  opacity: 0;
  margin: 0 auto;
  animation: ${fadeIn} 0.2s linear forwards;
  animation-delay: 1s;
  z-index: ${(p) => p.theme.zIndex.cookieNotice};

  @media screen and (min-width: ${breakpoints.small}) {
    flex-direction: inherit;
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    bottom: 2rem;
  }

  span {
    margin-bottom: 1rem;
    text-align: center;

    @media screen and (min-width: ${breakpoints.small}) {
      text-align: left;
      margin-bottom: 0;
      max-width: 70%;
    }
  }

  a {
    font-size: 0.75rem;
    color: white;

    &:after {
      left: inherit;
      right: 0;
      background: white;
      width: 100%;
    }

    &:hover {
      &:after {
        width: 0%;
      }
    }
  }

  svg {
    height: 0.75rem;
    width: 0.75rem;
    margin-left: 0.25rem;
  }
`

export const OkayButton = styled(Button)`
  padding: 0 2rem;
  height: 3rem;
  width: auto;
`

export const ShowSettings = styled(Box)`
  background: none;
  outline: none;
  border: none;
  padding: 0;
`
