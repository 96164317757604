import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import fetch from "isomorphic-unfetch"

export default function createApolloClient(initialState, ctx) {
  let uri = process.env.NEXT_PUBLIC_API_URL

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: new HttpLink({
      uri,
      fetch,
    }),
    cache: new InMemoryCache().restore(initialState),
  })
}
