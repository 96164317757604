import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { Notification as NotificationType } from "."
import Notification from "./Notification"

// TODO: Muting error, fix as soon as zeit/next.js/issues/7915 resolved
const originalError = console.error

console.error = (...args) => {
  if (/Warning.*useLayoutEffect does nothing on the server,/.test(args[0])) {
    return
  }
  originalError.call(console, ...args)
}

interface Props {
  notifications: NotificationType[]
  removeNotification: (id: string) => void
}

const List: React.FC<Props> = ({ notifications, removeNotification }) => {
  return (
    <motion.div
      suppressHydrationWarning
      layout
      style={{
        width: "100%",
        bottom: "1rem",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        pointerEvents: "none",
        zIndex: 99,
      }}
    >
      <AnimatePresence>
        {notifications.map((item) => (
          <motion.div
            suppressHydrationWarning
            layout
            key={item.id}
            transition={{ type: "spring", stiffness: 70 }}
            initial={{ opacity: 1, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            style={{ maxWidth: "22rem", width: "90%", pointerEvents: "auto" }}
          >
            <Notification
              type={item.type}
              onClose={() => removeNotification(item.id)}
            >
              {item.text}
            </Notification>
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  )
}

export default List
